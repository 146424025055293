import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Gallery({ imagesList }) {
  const [selectedTile, setSelectedTile] = React.useState(null);
  const [value, setValue] = React.useState([]);

  const handleClickOpen = (tile) => {
    setSelectedTile(tile);
  };

  const handleClose = () => {
    setSelectedTile(null);
  };
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
      }}
    >
      <ImageList sx={{ width: "500", height: "450" }}>
        <ImageListItem key="Subheader" cols={4}>
          <ListSubheader component="div">Gallery</ListSubheader>
        </ImageListItem>
        {imagesList.map((item) => (
          <ImageListItem key={item.img}>
            <img
              style={{ height: 300 }}
              src={`${item.images[0]}?w=248&fit=crop&auto=format`}
              srcSet={`${item.images[0]}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.createdAt}
              loading="lazy"
            />
            <ImageListItemBar
              title={moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.createdAt}`}
                  onClick={() => handleClickOpen(item)}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Dialog
        fullScreen
        open={selectedTile !== null}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {selectedTile && (
              <Typography variant="h6">
                {moment(selectedTile.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
              </Typography>
            )}
          </Toolbar>
        </AppBar>

        {selectedTile && (
          <img src={selectedTile.images[0]} alt={selectedTile.createdAt} />
        )}
      </Dialog>
    </div>
  );
}
