import { API_ROUTE } from "../constants/workerConstants";
import AxiosInstance from "./http.handlers";

export const GetUsers = async () => {
  return await AxiosInstance.get(`${API_ROUTE.USERS.GET_USERS}`)
    .then((response) => response)
    .catch((error) => error);
};

export const GetPainListsDaily = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.USERS.PAIN_LISTS_DAILY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetPainListsWeekly = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.USERS.PAIN_LISTS_WEEKLY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GetPainListsUser = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.USERS.PAIN_LISTS_MONTHLY}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const sendUserNotification = async (payload) => {
  return await AxiosInstance.post(
    `${API_ROUTE.USERS.SEND_NOTIFICATION}`,
    payload
  )
    .then((response) => response)
    .catch((error) => error);
};

export const getUserGalllery = async (payload) => {
  return await AxiosInstance.get(
    `${API_ROUTE.USERS.GET_GALLERY_IMAGES}/${payload}`
  )
    .then((response) => response)
    .catch((error) => error);
};
