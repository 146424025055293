import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const LoggedInRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.user.authToken);
  let location = useLocation();
  if (isAuthenticated == null) {
    // Redirect them to the /dashboard page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default LoggedInRoute;
