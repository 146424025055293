import * as React from "react";
import DashboardLayout from "../../Layout/DashboardLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetPainListsDaily,
  GetPainListsUser,
  GetPainListsWeekly,
  sendUserNotification,
} from "../../services/user.handlers";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Link as RouterLink, useLocation } from "react-router-dom";
import LineChart from "../../Components/Chart/LineChart";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Link from "@mui/material/Link";

const UserInfo = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(true);
  const [selectData, setSelectData] = React.useState("daily");
  const [labels, setLabels] = React.useState([]);
  const [stepCount, setStepCount] = React.useState([]);
  const [painData, setPainData] = React.useState([]);
  const params = useParams();
  const location = useLocation();
  const { item } = location.state;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  React.useEffect(() => {
    if (selectData === "daily") {
      fetchDailyData();
    } else if (selectData === "weekly") {
      fetchWeeklyData();
    } else if (selectData === "monthly") {
      fetchMonthlyData();
    }
  }, [selectData]);

  const handleChange = (event) => {
    setSelectData(event.target.value);
  };

  const fetchDailyData = () => {
    GetPainListsDaily({ userId: params.id }).then((response) => {
      if (response.status) {
        setData(response.data.data);
        const labels = response.data.data.map((item) =>
          moment(item.createdAt).format("DD MMM HH:mm:ss")
        );
        const painData = response.data.data.map((item) => item.painLevel);
        const stepsCountData = response.data.data.map(
          (item) => item.step_count.stepCount
        );
        // console.log("labels", labels, painData, stepsCountData);
        setLabels(labels);
        setPainData(painData);
        setStepCount(stepsCountData);
      }
    });
  };

  const fetchWeeklyData = () => {
    GetPainListsWeekly({ userId: params.id }).then((response) => {
      if (response.status) {
        setData(response.data.data);
        const labels = response.data.data.map((item) =>
          moment(item._id).format("ddd, DD MMM")
        );
        const painData = response.data.data.map((item) => item.average);
        const stepsCountData = response.data.data.map(
          (item) => item.step_count
        );
        setLabels(labels);
        setPainData(painData);
        setStepCount(stepsCountData);
      }
    });
  };

  const fetchMonthlyData = () => {
    GetPainListsUser({ userId: params.id }).then((response) => {
      if (response.status) {
        setData(response.data.data);
        const labels = response.data.data.map((item) =>
          moment(item._id).format("DD MMM")
        );
        const painData = response.data.data.map((item) => item.average);
        const stepsCountData = response.data.data.map(
          (item) => item.step_count
        );
        setLabels(labels);
        setPainData(painData);
        setStepCount(stepsCountData);
      }
    });
  };

  const sendNotification = () => {
    sendUserNotification({ userId: params.id }).then((response) => {
      if (response.data.status === 200) {
        if (
          response.data.data.results.length > 0 &&
          response.data.data.results[0].error !== undefined
        ) {
          alert(response.data.data.results[0].error.message);
        } else {
          alert("Sent Successfully");
        }
      } else {
        alert(response.data.message);
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <DashboardLayout>
      <Stack spacing={2} direction="row" style={{ marginBottom: "30px" }}>
        <p style={{ width: "65%" }}>
          {item.name + " " + item.surname} ({item.email})
        </p>
        <Link
          to={`/userGallery/${item._id}`}
          component={RouterLink}
          variant="p"
          className={"button-link"}
          state={{ item }}
        >
          Gallery
        </Link>
        <FormControl style={{ width: "13%" }}>
          <InputLabel id="demo-simple-select-label">Data</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectData}
            label="Data"
            onChange={handleChange}
          >
            <MenuItem value={"daily"}>Daily</MenuItem>
            <MenuItem value={"weekly"}>Weekly</MenuItem>
            <MenuItem value={"monthly"}>Monthly</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={() => sendNotification()} variant="contained">
          Check Pain Level
        </Button>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        style={{ marginBottom: "30px", justifyContent: "center" }}
      >
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button
            onClick={() => setShow(true)}
            variant={show ? "contained" : "outlined"}
          >
            Pain Level
          </Button>
          <Button
            onClick={() => setShow(false)}
            variant={show ? "outlined" : "contained"}
          >
            Steps Count
          </Button>
        </ButtonGroup>
      </Stack>
      {show ? (
        <LineChart
          labels={labels}
          painData={painData}
          labelText={"Pain Level"}
        />
      ) : (
        <LineChart
          labels={labels}
          painData={stepCount}
          labelText={"Steps Count"}
        />
      )}
      {/* <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">{"id"}</TableCell>
              <TableCell padding="checkbox">{"Pain Level"}</TableCell>
              <TableCell padding="checkbox">{"Date Time"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell style={{ width: "10%" }}>{index + 1}</TableCell>
                    <TableCell
                      style={{ width: "40%" }}
                      component="td"
                      // id={labelId}
                      scope="row"
                    >
                      {item.painLevel}
                    </TableCell>
                    <TableCell
                      component="td"
                      style={{ width: "50%" }}
                      // id={labelId}
                      scope="row"
                      padding="checkbox"
                    >
                      {moment(item.createdAt).format("DD MMM HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow
                hover
                //   onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                //   aria-checked={isItemSelected}
                tabIndex={-1}
              >
                <TableCell
                  style={{ width: "100%" }}
                  component="td"
                  colSpan={3}
                  align={"center"}
                  // id={labelId}
                  scope="row"
                  // padding="checkbox"
                >
                  No Records Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </DashboardLayout>
  );
};

export default UserInfo;
