import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loggedInUser } from "../../services/auth.handlers";

const initialState = {
  loading: false,
  status: null,
  authToken: null,
  userData: null,
  error: null,
};
export const loggedIn = createAsyncThunk("Auth/Login", async (data) => {
  const res = await loggedInUser(data);
  return res;
});

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    loggedOut(state) {
      state.loading = false;
      state.authToken = null;
      state.status = null;
      state.userData = null;
    },
  },
  extraReducers: {
    [loggedIn.pending]: (state) => {
      state.loading = true;
    },
    [loggedIn.fulfilled]: (state, { payload }) => {
      console.log("payloadpayload", payload);
      state.loading = false;
      state.status = payload.data.status === 200 ? true : false;
      if (payload.data.status === 200) {
        state.authToken = payload.data.authtoken;
        state.userData = payload.data.data;
        state.error = null;
      } else {
        state.error = "Invalid Credentials";
      }
    },
    [loggedIn.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { loggedOut } = AuthSlice.actions;

export default AuthSlice.reducer;
