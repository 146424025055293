import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    y: {
      beginAtZero: true,
      // max: 10,
      ticks: {
        // stepSize: 1,
        callback: function (label, index, labels) {
          // when the floored value is the same as the value we have a whole number
          if (Math.floor(label) === label) {
            return label;
          }
        },
      },
    },
  },
  stacked: false,
  plugins: {
    legend: {
      position: "left",
    },
    title: {
      display: true,
      text: "Date Time",
      position: "bottom",
    },
  },
};

export default function LineChart({ labels, painData, labelText }) {
  const data = {
    labels: labels, //["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: labelText,
        data: painData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return <Line options={options} data={data} />;
}
