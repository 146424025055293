import React from "react";
import { Routes, Route } from "react-router";
import LoggedOutRoute from "./loggedOutRoutes";
import LoggedInRoute from "./loggedInRoutes";
import Login from "../Screens/Boarding/Login";
import Dashboard from "../Screens/Dashboard";
import UserInfo from "../Screens/Dashboard/UserInfo";
import UserGallery from "../Screens/Dashboard/UserGallery";

const AppRoutes = (props) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <LoggedOutRoute>
            <Login />
          </LoggedOutRoute>
        }
      />
      <Route
        exact
        path="/dashboard"
        element={
          <LoggedInRoute>
            <Dashboard />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/user/:id"
        element={
          <LoggedInRoute>
            <UserInfo {...props} />
          </LoggedInRoute>
        }
      />
      <Route
        exact
        path="/userGallery/:id"
        element={
          <LoggedInRoute>
            <UserGallery {...props} />
          </LoggedInRoute>
        }
      />
    </Routes>
  );
};
export default React.memo(AppRoutes);
