// export const API_PATH =
//   "https://paindeduction.westus.cloudapp.azure.com/backend/api/";
//https://paindetection.nssplindia.com/
export const API_PATH = "https://paindetection.nssplindia.com/backend/api/";
export const API_ROUTE = {
  AUTH: {
    LOGGED_IN: "user/login",
  },
  USERS: {
    GET_USERS: "user/getUsers",
    PAIN_LISTS_DAILY: "user/dailyData",
    PAIN_LISTS_WEEKLY: "user/UserPainDataWeekly",
    PAIN_LISTS_MONTHLY: "user/UserPainDataList",
    SEND_NOTIFICATION: "user/sendPushNotificationForUser",
    GET_GALLERY_IMAGES: "user/getUsersGalleryImages",
  },
};
