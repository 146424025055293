import AuthReducer from "./authSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
};

export const RootReducer = combineReducers({
  user: persistReducer(persistConfig, AuthReducer),
});
