import * as React from "react";
import DashboardLayout from "../../Layout/DashboardLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { getUserGalllery } from "../../services/user.handlers";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Gallery from "../../Components/Gallery";
import moment from "moment";

const UserGallery = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [selectData, setSelectData] = React.useState("daily");
  const [labels, setLabels] = React.useState([]);
  const [stepCount, setStepCount] = React.useState([]);
  const [painData, setPainData] = React.useState([]);
  const params = useParams();
  const location = useLocation();
  const { item } = location.state;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  React.useEffect(() => {
    GetchGallery();
  }, [params.id]);

  const GetchGallery = () => {
    getUserGalllery(params.id).then((response) => {
      if (response.data.status == 200) {
        setData(response.data.data);
      }
    });
  };

  return (
    <DashboardLayout>
      <Stack spacing={2} direction="row" style={{ marginBottom: "30px" }}>
        <p style={{ width: "65%" }}>
          {item.name + " " + item.surname} ({item.email})
        </p>
      </Stack>
      <Gallery imagesList={data} />
    </DashboardLayout>
  );
};

export default UserGallery;
